<!--
 * @Author: xr
 * @Date: 2022-02-21 11:33:07
 * @LastEditors: xr
 * @LastEditTime: 2022-02-21 11:42:40
 * @FilePath: \postStation-office\src\views\declaration.vue
-->
<template >
  <div class="main">
    <div class="main_box">
      <a-row type="flex" :gutter="20" justify="space-between" style="padding:0px 10px 10px;">
        <a-col :span="7" style="padding-left: 0px">
          <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
          <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
        </a-col>
        <a-col :span="17" style="min-height:62vh">
          <a-row style="padding-top:10px">
            <article class="introduction">
              <p>吉林科技大市场（http://www.jlpstm.com，以下简称“本网站”）声明，系本网站保护用户隐私及网站服务有关条款的承诺。凡浏览本网站及相关网页的用户，均表示接受以下声明及条款：</p>
              <p class="text">一、本网站网页所涉及的任何资料（例如文字、图片、标识、域名、版面设计、专栏目录与名称等）的版权均属本网站或作者所有。未经本网站明确书面许可，任何媒体、网站或个人不得转载、链接、转贴、仿造或以其他方式复制发表。</p>
              <p class="text">二、本网站的版式和外观是依据内容而特别设计的，版权均属本网站所有，未经本网站授权不得以任何方式拷贝、使用本网站的版式和外观。</p>
              <p class="text">对于使用本网站链接的其他网站或网页而引起的任何损害，本网站不承担任何责任</p>
              <p class="text">四、本网站不保证与之相链接的各个网站信息的准确性、及时性和完整性，也不保证本网站材料的绝对准确性和完整性。我们可能在不通知的情况下随时变更本网站的内容或在这些内容中介绍的产品和价格。</p>
              <p class="text">五、当用户浏览本网站或参与网站各项活动时，为了做好用户服务工作，在用户同意并确认下，本网站可能会通过注册表格等形式要求您提供一些个人信息。以上个人资料在未经用户同意前，除国家法律、地方法规和政府规章规定允许之外，本网站不会用作他途。</p>
              <p class="text">六、用户对同意本网站所公布的本人信息有权利要求随时查阅、补充、更正或删除。当有关部门依照法定程序要求本网站披露用户个人信息时，本网站将根据其要求提供用户的个人信息。此种情况下的任何披露行为，本网站均应免责。</p>
              <p class="text"> 七、本网站尊重他人知识产权，如果您对网站内容有异议，请通过电子邮件或电话等方式联系，本网站将依据中华人民共和国相关法律法规进行处理，但香港、澳门、台湾适用的相关法律法规除外。</p>
              <p class="text"> 八、用户发生下列情形导致个人信息泄露的，本网站不承担任何责任：</p>
              <p class="text">1.由于用户将密码告知他人或与他人共享注册账户，由此导致的任何个人信息泄露</p>
              <p class="text">2.用户个人计算机被黑客攻击、病毒侵入或发作、政府部门管制和其他不可抗力等造成的个人资料泄露、丢失、被盗用或被篡改等；</p>
            </article>
          </a-row>
        </a-col>
      </a-row>
      <!-- 友情链接 -->
      <link-box />
    </div>
  </div>
</template>
<script>
import linkBox from '@comp/layout/link'
import FindDemand from '@comp/findDemand'
import { getDict } from '@/api/index'

export default {
  name: 'Declaration',
  components: {
    linkBox,
    FindDemand
  },
  data() {
    return {
      technicalProjectContent: [],
    }
  },
  methods: {

  },
  created() {
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
  }
}
</script>
<style lang="scss" scoped>
.introduction{
  .text{
    text-indent: 24px;
  }
}
@import "../assets/css/newsInformation.css";
</style>
